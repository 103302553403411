<template>
  <div id="top" class="menuback">
    <div class="divmn">
      <el-menu
        :default-active="activeIdx"
        class="el-menu2 el-menu-vertical-demo"
        mode="horizontal"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="main" @click="handleSelect('main')">
          <component class="menu_q" :is="iconHome"></component>
          <span class="menu_t" slot="title">首页</span>
        </el-menu-item>
        <el-menu-item
          @click="handleSelect(item.MKH)"
          v-for="item in menuOption"
          :key="item.ID"
          :index="item.MKH"
        >
          <component class="menu_q" :is="item.MKTB"></component>
          <span class="menu_t" slot="title">{{ item.MKMC }}</span>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activeTab: { type: String, default: "tzgg" },
    newsData: {},
  },
  data() {
    return {
      iconHome: "HomeFilled",
      url: this.env.srvHost + "/web",
      activeIdx: this.activeTab,
      routeObj: {
        name: "xw",
        params: { mkh: "0", newsid: "0" },
      },
      menuOption: this.env.menu, // 主菜单模块
    };
  },
  watch: {
    activeTab(n, o) {
      if (this.newsData && this.newsData.anymore) {
        this.activeIdx = n;
      }
    },
  },
  methods: {
    handleSelect(key) {
      let obj = { iskey: "newspage", mkh: key };
      this.$route.params.mkh = key;
      this.$route.params.zmkh = "";
      this.$EventBus.emit("toParent", obj);
      this.activeIdx = key;

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style scoped>
.zjw_ic {
  color: #fff;
  font-size: 26px !important;
}
.menu_t {
  margin-left: 6px;
  font-size: 20px;
}
.menu_q {
  width: 24px;
}
#top {
  height: 60px;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2001;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  user-select: none;
}
.divmn {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}
.el-menu-item {
  font-size: 18px;
  padding: 0 10px;
}
</style>