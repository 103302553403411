import { createRouter, createWebHistory } from 'vue-router';
import Main from '../views/FramePage.vue';
import ghy from '../components/portal/HomeGhy';
import ghxh from '../components/portal/HomeGhxh';
import ckh from '../components/portal/HomeCkh';

import friendlink from "../components/FriendLink.vue";

import newspage from "../components/news/NewsPage";
import newsquery from "../components/news/NewsQuery";
import row1col1 from "../components/titlebox/Row1Col1.vue";
import row1col3 from "../components/titlebox/Row1Col3.vue";
import pictureloop from "../components/titlebox/PictureLoop";
import row1but4 from "../components/titlebox/Row1But4";
import row1pic5 from "../components/titlebox/Row1Pic5";
import feedback from '../components/Feedback.vue';
import newslist from '../components/news/NewsList.vue';
import newsdetail from '../components/news/NewsDetailed.vue';

const routes = [
  {
    path: '/',
    component: Main,
    children: [{
      path: '',
      components: {
        ghy: ghy,
        ghxh: ghxh,
        ckh: ckh,
        newspage,
        newsquery,
        newsdetail
      },
      children: [{
        path: '',
        components: {
          row1col1,
          row1col3,
          pictureloop,
          row1but4,
          row1pic5,
          friendlink,
          feedback,
          newslist,
        }
      }]
    }]
  },
  {
    path: '/news/:newsid',
    name: 'webpage',
    component: () => import('../views/Webpage.vue')
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
