// 分割线+新闻标题格式
<template>
  <div class="a1at2 nstyle">
    <HrLine2 :title="mkmc" :mkh="mkPara.MKH" />
    <ul class="nl">
      <li class="n" v-for="item in newsList" :key="item.ID">
        <a href="javascript:;" @click="getNews(item)">{{ item.WYMC }}</a>
        <!-- <a :href="'/news/' + item.ID" target="_blank">{{ item.WYMC }}</a> -->
        <b>{{ item.FBSJ }}</b>
      </li>
    </ul>
  </div>
</template>
<script>
import Axios from "axios";
import HrLine2 from "../line/HrLine2.vue";
// 网页第一行中的第二个div的内容
export default {
  name: "r1at2",
  props: {
    mkh: { type: String, default: "r1c2" }, // 显示模块的位置
    anymore: { type: String, default: "#" }, // 更多跳转的URL
  },
  components: {
    HrLine2,
  },
  data() {
    return {
      url: this.env.srvHost + "/web",
      mkmc: "",
      mkPara: {},
      newsList: [],
    };
  },
  created() {
    this.mkPara = this.env.mkparas[this.mkh];
    this.mkmc = this.mkPara ? this.mkPara.MKMC : "其他";

    this.getNewsList();
  },
  methods: {
    getNewsList() {
      let my = this;
      Axios.get(my.url + "/mkzx/xwlb", {
        params: {
          portal: my.env.portal,
          mkh: my.mkh,
        },
      })
        .then(function (resp) {
          my.newsList = resp.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getNews(obj) {
      let para = {
        iskey: "newsdetail",
        mkh: "",
        zmk: obj.P_ID,
        newsid: obj.ID,
        anymore: true,
      };

      this.$route.params.zmk = obj.P_ID;
      this.$route.params.newsid = obj.ID;
      this.$EventBus.emit("toParent", para);
    },
  },
};
</script>
<style scoped>
.a1at2 {
  margin-left: 5px;
}
ul {
  list-style: none;
}
ul.nl {
  margin: 0;
  padding: 0;
}
ul.nl li {
  height: 32px;
  width: 100%;
}
li.n a {
  width: calc(100% - 100px);
  display: block;
  float: left;
  line-height: 32px;
  overflow: hidden;
  color: #222;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
}
li.n b {
  width: 88px;
  font-style: normal;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: relative;
  word-spacing: 4px;
}

ul.nl li b {
  font-weight: normal;
}
</style>