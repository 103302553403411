<template>
  <div class="foot">
    <div class="w1200 ck-content" v-html="htmltext"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      htmltext: "版权所有",
      url: this.env.srvHost + "/web",
      portalName: this.env.portal,
    };
  },
  mounted() {
    this.getFoot();
  },
  methods: {
    getFoot() {
      let my = this;
      axios
        .get(my.url + "/getfoot", {
          params: {
            portal: my.env.portal,
          },
        })
        .then(function (resp) {
          let data = resp.data;
          if (data.length > 0) {
            my.htmltext = data[0].wynr;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.foot {
  /* position: fixed; */
  /* z-index: 10; */
  bottom: 0;
  /* width: 100%; */
  /* color: #fff; */
  background-color: #127aea0c;
  /* text-align: center; */
}

p {
  margin: 0;
}
</style>