<template>
  <div class="w1200 titleback">
    <img style="float: left; padding-top: 15px" :src="imgsrc" alt="" />
    <div style="width: 300px; position: absolute; right: 0px; bottom: 0px">
      <!-- <el-input
        v-model="wymc"
        placeholder="请输入内容"
        class="input-with-select"
      >
        <el-button slot="append" icon="el-icon-search" @click="queryNews"
          >搜索</el-button
        >
      </el-input> -->
    </div>
    <div class="clear"></div>
  </div>
</template>
<script>
import Axios from "axios";

export default {
  name: "search",
  data() {
    return {
      imgsrc: process.env.BASE_URL + "static/img/" + this.env.portal + ".png",
      url: this.env.srvHost + "/sys",
      wymc: "",
    };
  },
  mounted() {
    switch (this.env.portal) {
      case "ghy":
        document.title = "贵州省城乡规划设计研究院";
        break;
      case "ghxh":
        document.title = "贵州省城市规划协会";
        break;
      case "ckh":
        document.title = "贵州省城市科学研究会";
        break;
      default:
        document.title = "网页加载中";
        break;
    }
  },
  methods: {
    queryNews() {
      let my = this;
      if (my.wymc && my.wymc.trim().length > 0) {
        Axios.get(my.env.srvHost + "/web/xw/cx", {
          params: {
            portal: my.env.portal,
            wymc: my.wymc,
          },
        })
          .then(function (resp) {
            let data = resp.data;
            if (data.length > 0) {
              let key = "query";
              let obj = { iskey: "newsquery", mkh: key, data: data };
              my.$route.params.mkh = key;
              my.$EventBus.emit("toParent", obj);
              my.activeIdx = key;

              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        alert("请输入查询关键字！");
        return;
      }
    },
  },
};
</script>