// 新闻版块 + 三列展示9条新闻，有分隔线
<template>
  <dl class="clearfix nstyle">
    <HrLine :title="mkmc" :mkh="mkPara.MKH" />
    <dt>
      <ul class="nl">
        <li class="n">
          <a href="javascript:;" @click="getNews(maxItem)">
            <img :src="maxItem.TPDZ" />
          </a>
          <div class="tit">
            <a href="javascript:;" @click="getNews(maxItem)">
              {{ maxItem.WYMC }}</a
            >
          </div>
        </li>
      </ul>
    </dt>
    <dd>
      <ul class="nl">
        <li class="n" v-for="item in newsList.slice(1, 3)" :key="item.ID">
          <a href="javascript:;" @click="getNews(item)">
            <img :src="item.TPDZ" />
          </a>
          <div class="tit">
            <a href="javascript:;" @click="getNews(item)">{{ item.WYMC }}</a>
          </div>
        </li>
      </ul>
    </dd>
    <dd>
      <ul class="nl">
        <li class="n" v-for="item in newsList.slice(3, 5)" :key="item.ID">
          <a href="javascript:;" @click="getNews(item)">
            <img :src="item.TPDZ" />
          </a>
          <div class="tit">
            <a href="javascript:;" @click="getNews(item)">{{ item.WYMC }}</a>
          </div>
        </li>
      </ul>
    </dd>
  </dl>
</template>
<script>
import Axios from "axios";
import HrLine from "../line/HrLine";
export default {
  props: {
    mkh: { type: String, default: "" }, // 显示模块的位置
  },
  components: {
    HrLine,
  },
  data() {
    return {
      url: this.env.srvHost + "/web",
      mkmc: "无标题名称",
      mkPara: {},
      newsList: [],
      maxItem: {},
    };
  },
  created() {
    this.mkPara = this.env.mkparas[this.mkh];
    this.mkmc = this.mkPara ? this.mkPara.MKMC : "其他";

    this.getNewsList();
  },
  methods: {
    getNewsList() {
      let my = this;
      Axios.get(my.url + "/mkzx/xwlb", {
        params: {
          portal: my.env.portal,
          mkh: my.mkh,
          imgurl: 1,
        },
      })
        .then(function (resp) {
          my.newsList = resp.data;
          my.maxItem = my.newsList[0];
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getNews(obj) {
      let para = {
        iskey: "newsdetail",
        mkh: "",
        zmk: obj.P_ID,
        newsid: obj.ID,
        anymore: true,
      };
      this.$route.params.zmk = obj.P_ID;
      this.$route.params.newsid = obj.ID;
      this.$EventBus.emit("toParent", para);
    },
  },
};
</script>
<style scoped>
.clearfix:after,
dd,
dl,
dt,
li,
ol,
td,
th,
thead,
tr,
ul {
  margin: 0;
  padding: 0;
}
.clearfix a {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: black;
  line-height: 20px;
}
.clearfix a:hover {
  /* color: #127cea; */
}
.clearfix {
  width: 1200px;
  margin: 20px auto 0;
  display: block;
}
li {
  list-style-type: none;
  display: list-item;
  text-align: -webkit-match-parent;
}
dt {
  width: 640px;
  float: left;
}
dt .tit {
  font-size: 20px;
  font-weight: bolder;
  line-height: 30px;
  padding-top: 5px;
}
dt img {
  width: 640px;
  height: 360px;
  display: block;
  object-fit: cover;
}
dd {
  margin-left: 20px;
  width: 260px;
  float: left;
}
dd img {
  width: 260px;
  height: 146px;
  display: block;
  object-fit: cover;
}
dd .tit {
  padding-top: 10px;
  font-size: 16px;
  font-weight: bolder;
  line-height: 24px;
  text-align: center;
}
.mm {
  margin-left: 20px;
}
</style>