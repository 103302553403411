<template>
  <el-container
    style="width: 1200px; min-height: calc(100% - 210px); margin: 0 auto"
  >
    <el-aside width="200px" v-show="true">
      <el-menu :default-active="defaultZMk">
        <!-- style="background: #fbfdfd" -->
        <el-menu-item
          v-for="item in zmkList"
          @click="handleSelect(item)"
          :index="item.ID"
          :key="item.ID"
        >
          <i class="el-icon-document"></i>
          <span slot="title">{{ item.MKMC }}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <!-- 新闻列表、子模块新闻 -->
    <el-main>
      <router-view :name="rtname" :newsData="postData" />
    </el-main>
  </el-container>
</template>

<script>
import Axios from "axios";
export default {
  props: {
    newsData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      defaultZMk: "",
      showList: true,
      url: this.env.srvHost + "/web",
      zmkList: [],
      pagList: [],
      htmltext: "",
      isOne: 0,
      rtname: "",
      rtid: "",
      postData: {},
    };
  },
  watch: {
    newsData: {
      deep: true,
      handler: function (val, oldVal) {
        this.getZMK();
      },
    },
  },
  created() {
    this.getZMK();
  },
  methods: {
    getZMK() {
      // this.defaultZMk = this.$route.params.zmk;
      let my = this;
      my.pagList = [];
      let mkh = my.$route.params.mkh;
      Axios.get(my.url + "/zmk", {
        params: {
          portal: my.env.portal,
          mkh: mkh,
        },
      })
        .then(function (resp) {
          my.zmkList = resp.data;
          if (my.zmkList.length > 0) {
            my.handleSelect(my.zmkList[0]);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    handleSelect(item) {
      if (item.DYZMK == "1") {
        this.rtname = "feedback";
      } else {
        this.postData = item;
        this.postData.zmkh = item.ID;
        this.postData.mkh = this.newsData.mkh;
        this.postData.rdm = Math.random();
        this.rtname = "newslist";
      }
    },
  },
};
</script>

<style scoped>
.el-aside {
  color: #333;
}
.el-header {
  padding: 0;
  margin-left: 20px;
}
.el-main {
  color: #333;
  padding: 10px;
  min-height: 600px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>