<template>
  <div>
    <HrLine title="关于我们" mkh="gywm" />
    <div>
      <div style="float: left; width: 1000px; font-size: 16px">
        <p>
          贵州省城市科学研究会是由贵州省内从事城乡发展、城市科学研究，有一定理论和实践经验的专家、学者、工作者以及有关科研、院校、生产等单位自愿组成、依法登记的公益性、学术性、非营利性社会组织。本组织具有高智力、多学科、跨行业、综合性的特点，是促进贵州省城乡高质量发展的重要社会力量。
        </p>
      </div>
      <div
        style="
          float: right;
          width: 100px;
          margin-left: 50px;
          margin-right: 50px;
        "
      >
        <a href="javascript:;" @click="gotohere('申请入会')">
          <i><img :src="`${publicPath}/static/img/zjw_sqrh.png`" /></i>
          <h3>申请入会</h3>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import HrLine from "../line/HrLine.vue";
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      url: this.env.srvHost + "/web/getmkh",
    };
  },
  components: {
    HrLine,
  },
  methods: {
    gotohere(item) {
      let my = this;
      axios.get(this.url, { params: { mkmc: item } }).then((resp) => {
        let data = resp.data;
        if (data.success == "true") {
          let obj = {
            iskey: "newspage",
            mkh: data.zmk.MKH,
            zmk: data.zmk.ID,
            anymore: true,
          };
          my.$EventBus.emit("toParent", obj);
        }
      });
    },
  },
};
</script>
<style scoped>
a,
h3 {
  text-align: center;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  color: #127cea;
}
</style>