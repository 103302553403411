<template>
  <div class="zj_main">
    <span class="zj_tilte_first padding_ri_25">友情链接</span>
    <div class="zj_friend_list">
      <div style="float: left" v-for="item in tableData" :key="item.ID">
        <a
          style="display: inline"
          :href="item.DZ"
          :title="item.MC"
          target="_blank"
          >{{ item.MC }} </a
        >|
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>
<script>
import Axios from "axios";
export default {
  data() {
    return {
      url: this.env.srvHost + "/web",
      tableData: [{ ID: "", SX: "", MC: "", DZ: "" }],
    };
  },
  mounted() {
    this.getLink();
  },
  methods: {
    getLink() {
      let my = this;
      Axios.get(my.url + "/getlink", { params: { portal: my.env.portal } })
        .then(function (resp) {
          let data = resp.data;
          if (data.length > 0) my.tableData = data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.zj_main {
  background-color: #c9def569;
  padding-bottom: 40px;
}
.zj_main a {
  color: #000;
  text-decoration: none;
}
.zj_main a:hover {
  color: #127cea;
}
.padding_ri_25 {
  padding: 0 25px 0 15px;
}
.zj_tilte_first {
  font-size: 20px;
  float: left;
  font-family: "微软雅黑", "黑体";
  display: inline-block;
  *display: inline;
  *zoom: 1;
  font-weight: bold;
  color: #333333;
  /* background: url(../img/zj_green_left_bg.jpg) no-repeat left center; */
}
.zj_friend_list {
  line-height: 32px;
  width: 1077px;
  float: right;
  font-family: "微软雅黑", "黑体";
}
</style>