<template>
  <ul class="nl">
    <li
      class="n"
      v-for="item in pagList"
      @click="getNews(item.ID)"
      :key="item.ID"
    >
      <a href="javascript:;">{{ item.WYMC }}</a>
      <b>{{ item.FBSJ }}</b>
    </li>
  </ul>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      url: this.env.srvHost + "/web",
      pagList: [],
    };
  },

  props: {
    newsData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  methods: {
    getNewsList(zmkId) {
      let my = this;
      axios
        .get(my.url + "/zmk/lb", {
          params: {
            portal: my.env.portal,
            mkid: zmkId,
          },
        })
        .then(function (resp) {
          my.pagList = resp.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    getNews(xwId) {
      let obj = {
        iskey: "newsdetail",
        mkh: this.newsData.mkh,
        zmkh: this.newsData.zmkh,
      };
      this.$route.params.newsid = xwId;
      this.$EventBus.emit("toParent", obj);
    },
  },
  mounted() {
    this.getNewsList(this.newsData.zmkh);
  },
  watch: {
    newsData: {
      deep: true,
      handler: function (val, oldVal) {
        this.getNewsList(val.zmkh);
      },
    },
  },
};
</script>

<style scoped>
/* 子模块样式 */
ul {
  list-style: none;
}
ul.nl {
  margin: 0;
  padding: 0;
}
ul.nl li {
  height: 33px;
  /* width: 800px; */
}
li.n a {
  width: calc(100% - 110px);
  display: block;
  float: left;
  line-height: 32px;
  overflow: hidden;
  color: #222;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  padding-left: 10px;
}
li.n b {
  color: #2a63dd;
  width: 88px;
  font-style: normal;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: relative;
  word-spacing: 4px;
}

ul.nl li:hover {
  cursor: hand;
  background: #c1dff8;
}

#side_func {
  margin-right: -660px;
  right: 50%;
  position: fixed;
  top: 190px;
  /* height: 232px; */
  width: 58px;
  z-index: 1500;
  /* background: #afd5f5; */
}
</style>