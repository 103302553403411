// 1200px 横排展示4个图标
<template>
  <div class="btn_navigation">
    <div class="nav_itm">
      <div style="float: left">
        <i><img :src="`${publicPath}/static/img/zjw_sjpy.png`" /></i>
      </div>
      <div style="float: left; margin-left: 15px">
        <a href="javascript:;" @click="gotohere('省级评优')">
          <h3>省级评优</h3>
        </a>
      </div>
    </div>
    <div class="nav_itm">
      <div style="float: left">
        <i><img :src="`${publicPath}/static/img/zjw_hdbm.png`" /></i>
      </div>
      <div style="float: left; margin-left: 15px">
        <a href="javascript:;" @click="gotohere('活动报名')">
          <h3>活动报名</h3>
        </a>
      </div>
    </div>
    <div class="nav_itm">
      <div style="float: left">
        <i><img :src="`${publicPath}/static/img/zjw_zgzx.png`" /></i>
      </div>
      <div style="float: left; margin-left: 15px">
        <a href="javascript:;" @click="gotohere('注规资讯')">
          <h3>注规资讯</h3>
        </a>
      </div>
    </div>
    <div class="nav_itm">
      <div style="float: left">
        <i><img :src="`${publicPath}/static/img/zjw_zlxz.png`" /></i>
      </div>
      <div style="float: left; margin-left: 15px">
        <a href="javascript:;" @click="gotohere('资料下载')">
          <h3>资料下载</h3>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      url: this.env.srvHost + "/web/getmkh",
    };
  },
  mounted() {
    // console.log(process.env.BASE_URL);
  },
  methods: {
    gotohere(item) {
      let my = this;
      axios.get(this.url, { params: { mkmc: item } }).then((resp) => {
        let data = resp.data;
        if (data.success == "true") {
          let obj = {
            iskey: "newsdetail",
            mkh: data.zmk.MKH,
            zmk: data.zmk.ID,
            anymore: true,
          };
          my.$EventBus.emit("toParent", obj);
        }
      });
    },
  },
};
</script>

<style scoped>
h3,
a {
  margin: 0;
  padding: 0;
  list-style: none;
}
.btn_navigation {
  width: 100%;
  display: inline-flex;
  text-align: center;
  background: url(/static/img/zjw_four.jpg) no-repeat center;
}
.nav_itm {
  margin-inline-start: 80px;
  display: inline-flex;
  height: 100px;
  width: 280px;
  align-items: center;
}

.nav_itm a {
  color: #333;
  text-decoration: none;
}
.nav_itm a:hover {
  color: #127cea;
}
.nav_itm i {
  background-color: #127cea;
  display: block;
  margin: auto;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  border: 4px solid #f5f5f5;
}
</style>