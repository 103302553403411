// 新闻版块 + 三列展示9条新闻，有分隔线
<template>
  <div class="zjw_r1c3">
    <HrLine :title="mkmc" :mkh="mkPara.MKH" />
    <ul>
      <li v-for="item in newsList.slice(0, 3)" :key="item.ID">
        <a href="javascript:;" @click="getNews(item)">{{ item.WYMC }}</a>
      </li>
    </ul>
    <ul>
      <li v-for="item in newsList.slice(3, 6)" :key="item.ID">
        <a href="javascript:;" @click="getNews(item)">{{ item.WYMC }}</a>
      </li>
    </ul>
    <ul>
      <li v-for="item in newsList.slice(6, 9)" :key="item.ID">
        <a href="javascript:;" @click="getNews(item)">{{ item.WYMC }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import Axios from "axios";
import HrLine from "../line/HrLine";
export default {
  props: {
    mkh: { type: String, default: "" }, // 显示模块的位置
  },
  components: {
    HrLine,
  },
  data() {
    return {
      url: this.env.srvHost + "/web",
      mkmc: "无标题名称",
      mkPara: {},
      newsList: [],
    };
  },
  created() {
    this.mkPara = this.env.mkparas[this.mkh];
    this.mkmc = this.mkPara ? this.mkPara.MKMC : "其他";

    this.getNewsList();
  },
  methods: {
    getNewsList() {
      let my = this;
      Axios.get(my.url + "/mkzx/xwlb", {
        params: {
          portal: my.env.portal,
          mkh: my.mkh,
        },
      })
        .then(function (resp) {
          my.newsList = resp.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 打开新标签显示新闻
    getPage(obj) {
      this.$router.push({
        name: "xw",
        params: { mkh: "hyzx", newsid: obj.ID },
        query: { zmk: obj.P_ID },
      });
    },
    // 当前页面显示新闻
    getNews(obj) {
      let para = {
        iskey: "newsdetail",
        mkh: "",
        zmk: obj.P_ID,
        newsid: obj.ID,
        anymore: true,
      };
      this.$route.params.zmk = obj.P_ID;
      this.$route.params.newsid = obj.ID;
      this.$EventBus.emit("toParent", para);
    },
  },
};
</script>
<style scoped>
.zjw_r1c3 {
  width: 1200px;
  float: left;
}

.zjw_r1c3 ul,
li,
a {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: black;
  line-height: 30px;
}
.zjw_r1c3 a:hover {
  color: #127cea;
}
.zjw_r1c3 ul {
  width: 400px;
  float: left;
  overflow: hidden;
  padding-inline-start: 0px;
}
.zjw_r1c3 li {
  width: 360px;
  padding-left: 25px;
  padding-right: 15px;
  background: url("/static/img/zj_talk.jpg") no-repeat left center;
  /* font: 14px/32px "微软雅黑"; */
  height: 30px;
  overflow: hidden;
}
</style>