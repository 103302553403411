<template>
  <div style="width: 1200px; margin: 0 auto">
    <div id="side_func">
      <a class="sfa back" href="javascript:;" @click="goBack">返回</a>
      <a class="sfa gotop" href="javascript:;" @click="goTop">顶部</a>
    </div>
    <div class="ck-content" v-html="htmltext"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    newsData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      xwid: "",
      url: this.env.srvHost + "/web",
      htmltext: "",
    };
  },
  mounted() {
    this.getNews();
  },
  methods: {
    getNews() {
      let my = this;
      my.xwid = this.$route.params.newsid;
      axios
        .get(my.url + "/zmk/wy", {
          params: {
            portal: my.env.portal,
            wyid: my.xwid,
          },
        })
        .then(function (resp) {
          let data = resp.data;
          if (data.length > 0) {
            my.htmltext = data[0].WYNR;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    goTop() {
      scrollTo(0, 0);
    },

    goBack() {
      let obj = { iskey: "newspage", mkh: this.newsData.mkh, flash: "need" };
      obj.mkh = obj.mkh || "main";
      this.$route.params.mkh = obj.mkh;
      this.$route.params.newsid = "";
      this.$route.params.zmkh = "";
      this.$EventBus.emit("toParent", obj);
    },
  },
};
</script>

<style scoped>
#side_func {
  position: fixed;
  padding-left: 1203px;
}

#side_func a.sfa {
  vertical-align: bottom;
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  overflow: hidden;
  z-index: 10;
  font-size: 12px;
  border: 1px #f0f0f0 solid;
  margin-bottom: -1px;
}

#side_func a.gotop {
  line-height: 76px !important;
  font-size: 10px;
  color: #fff;
  background: url(../../assets/toph.svg) 15px 8px no-repeat;
  background-size: 20px;
  background-color: #6d8d63;
}

#side_func a.back {
  line-height: 76px !important;
  font-size: 10px;
  color: #fff;
  background: url(../../assets/back.svg) 15px 8px no-repeat;
  background-size: 20px;
  background-color: #6d8d63;
}

#side_func a {
  text-decoration: none;
}
</style>