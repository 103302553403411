<template>
  <div>
    <p>
      我们很重视贵州省城市科学研究会的门户网站建设，如您对网站建设有更好的意见或建议，欢迎您提交给我们。您可以通过邮箱（gzsckh@126.com）发送，或直接在下方输入框输入并提交。
    </p>
    <el-input
      v-model="textarea"
      maxlength="300"
      placeholder="少于300字"
      :autosize="{ minRows: 5 }"
      type="textarea"
    />
    <div class="btndiv">
      <el-button type="success" @click="sendMsg" :disabled="isDisable"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      textarea: "",
      url: this.env.srvHost + "/web/sendmsg",
      isDisable: false,
    };
  },
  methods: {
    sendMsg() {
      let formdata = new FormData();
      formdata.append("portal", this.env.portal);
      formdata.append("msg", this.textarea);
      let my = this;
      axios.post(my.url, formdata).then((resp) => {
        my.$message.success(resp.data.message);
      });
      this.isDisable = true;
    },
  },
};
</script>

<style scoped>
.btndiv {
  text-align: center;
  padding-top: 30px;
}
</style>