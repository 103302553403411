<template>
  <el-container
    style="
      width: 1200px;
      min-height: calc(100% - 202px);
      background: #fbfdfd;
      margin: 0 auto;
    "
  >
    <el-aside width="200px" v-show="true">
      <el-menu :default-active="defaultZMk" @select="handleSelect">
        <!-- style="background: #fbfdfd" -->
        <el-menu-item>
          <i class="el-icon-document"></i>
          <span slot="title">查询结果>></span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <!-- 新闻列表 -->
    <el-main v-show="showList">
      <ul class="nl">
        <li class="n" v-for="item in pagList" :key="item.ID">
          <a href="javascript:;" @click="getNews(item.ID)">{{ item.WYMC }}</a>
          <b>{{ item.FBSJ }}</b>
        </li>
      </ul>
    </el-main>
    <!-- 新闻内容 -->
    <el-container
      style="width: 1200px; border: 1px solid #eee"
      v-show="!showList"
    >
      <div id="side_func">
        <el-button
          type="text"
          icon="el-icon-d-arrow-left"
          style="font-size: 20px"
          @click="showList = !showList"
          >返回</el-button
        >
      </div>
      <el-main class="ck-content" v-html="htmltext"></el-main>
    </el-container>
  </el-container>
</template>

<script>
import Axios from "axios";
export default {
  props: {
    newsData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      defaultZMk: "",
      showList: true,
      url: this.env.srvHost + "/web",
      zmkList: [],
      pagList: [],
      htmltext: "",
      isOne: 0,
    };
  },
  watch: {
    newsData: {
      handler: function (val, oldVal) {
        // console.log(JSON.stringify( val));
        this.getNewsList();
      },
    },
  },
  methods: {
    getNewsList() {
      this.pagList = this.newsData.data;
    },
    getNews(xwId) {
      let my = this;
      Axios.get(my.url + "/zmk/wy", {
        params: {
          portal: my.env.portal,
          wyid: xwId,
        },
      })
        .then(function (resp) {
          my.showList = false;
          let data = resp.data;
          if (data.length > 0) {
            my.htmltext = data[0].WYNR;
          }
          my.$route.params.zmk = "";
          my.$route.params.newsid = "";
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    handleSelect() {
      this.showList = true;
    },
  },
};
</script>

<style scoped>
.el-aside {
  color: #333;
}
.el-header {
  padding: 0;
  margin-left: 20px;
}
.el-main {
  color: #333;
  padding: 10px;
  min-height: 600px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

/* 子模块样式 */
ul {
  list-style: none;
}
ul.nl {
  margin: 0;
  padding: 0;
}
ul.nl li {
  height: 33px;
  width: 800px;
}
li.n a {
  width: calc(100% - 110px);
  display: block;
  float: left;
  line-height: 32px;
  overflow: hidden;
  color: #222;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  padding-left: 10px;
}
li.n b {
  color: #2a63dd;
  width: 88px;
  font-style: normal;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: relative;
  word-spacing: 4px;
}
ul.nl li:hover a {
  min-width: 800px;
  height: 28px;
  line-height: 28px;
  margin-top: 2px;
  width: initial !important;
  overflow: hidden;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 2000;
  position: absolute;
  background: #fff6ea;
  border: 1px solid #b5dcfc;
  padding-right: 9px;
  padding-left: 9px;
  border-radius: 3px;
}

#side_func {
  margin-right: -660px;
  right: 50%;
  position: fixed;
  top: 190px;
  height: 232px;
  width: 58px;
  z-index: 1500;
}
</style>