<template>
  <el-carousel indicator-position="none" height="320px">
    <el-carousel-item v-for="item in imgdata" :key="item.ID" label="sfe">
      <div>
        <a href="javascript:;" @click="getNews(item)">
          <img :src="item.TPDZ" />
          <h3 class="a_s">
            <span>{{ item.WYMC }}</span>
          </h3>
        </a>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
import Axios from "axios";
// 网页第一行中的第二个div的内容
export default {
  name: "r1at1",
  data() {
    return {
      imgdata: [],
    };
  },
  mounted() {
    this.getImgList();
  },
  methods: {
    getImgList() {
      let _this = this;
      Axios.get(_this.env.srvHost + "/web/tplb", {
        params: {
          portal: _this.env.portal,
        },
      })
        .then(function (resp) {
          _this.imgdata = resp.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getNews(obj) {
      let para = {
        iskey: "newsdetail",
        mkh: "",
        zmk: obj.ZMKID,
        newsid: obj.WYID,
        anymore: true,
      };
      this.$route.params.zmk = obj.ZMKID;
      this.$route.params.newsid = obj.WYID;
      this.$EventBus.emit("toParent", para);
    },
  },
};
</script>
<style scoped>
a {
  width: 100%;
  text-decoration: none;
  color: rgb(255, 255, 255);
}
a img {
  height: 320px;
  width: 100%;
  display: block;
  object-fit: cover;
}
.a_s {
  background-color: #127aea8f;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
h3 {
  margin: 0;
  padding: 0;
  height: 32px;
  text-align: center;
}
</style>