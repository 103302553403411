// 分割横线，标题在线的左上方
<template>
  <div class="zjw_topic_h2">
    <div class="zjw_ti">
      <span
        style="
          font-weight: 600;
          float: left;
          border-bottom: 6px solid #2e75b6;
          user-select: none;
        "
        >{{ title }}</span
      >
      <span style="float: right; right: 0"
        ><a href="javascript:;" @click="handleClick">更多>></a></span
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "无标题名称" },
    mkh: String,
  },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      let obj = { iskey: "newspage", mkh: this.mkh, anymore: true };
      this.$route.params.mkh = obj.mkh;
      this.$EventBus.emit("toParent", obj);
    },
  },
};
</script>
<style scoped>
.zjw_topic_h2 {
  height: 40px;
  background: url(/static/img/zj_blue_bg.jpg) repeat-x left 31px;
}
.zjw_topic_h2 a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  user-select: none;
  color: #aab5c0;
  font-size: 16px;
  text-align: center;
}
.zjw_topic_h2 a:hover {
  color: #127cea;
}
.zjw_ti {
  font-size: 20px;
  color: #333333;
  font-family: "微软雅黑", "黑体";
  text-align: center;
}
</style>