<template>
  <div style="height: calc(100% - 60px)">
    <!-- <div style="height: 100%"> -->
    <FrameMenu :activeTab="activeTab" :newsData="currentData" />
    <div style="padding-top: 60px"></div>
    <FrameTitle />
    <router-view :name="rtName" :newsData="currentData" />
    <FrameFoot />
  </div>
</template>
<script>
import FrameMenu from "../components/frame/FrameMenu.vue";
import FrameTitle from "../components/frame/FrameTitle.vue";
import FrameFoot from "../components/frame/FrameFoot.vue";
export default {
  components: {
    FrameMenu,
    FrameFoot,
    FrameTitle,
  },
  data() {
    return {
      activeTab: "main",
      rtName: this.env.portal,
      currentData: { iskey: "", mkh: "" },
    };
  },
  mounted() {
    this.$EventBus.on("toParent", this.parentMethod);
  },
  methods: {
    parentMethod(obj) {
      if (obj.mkh === "main") {
        this.rtName = this.env.portal;
      } else {
        this.rtName = obj.iskey;
        this.currentData = obj;
      }
      if (obj.mkh) this.activeTab = obj.mkh;
    },
  },
};
</script>
<style>
body {
  margin: 0 auto;
  padding: 0;
  font-family: "微软雅黑", "黑体";
  font-size: 14px;
  min-width: 1200px;
}
</style>