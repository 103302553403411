// 分割横线，标题在线的左侧方
<template>
  <div class="zj_topic_h2">
    <span class="zj_training_information">{{ title }}</span>
    <cite class="zj_more_2">
      <!-- <a href="javascript:;" @click="handleClick">更多</a> -->
    </cite>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "无标题名称" },
    mkh: String,
  },

  methods: {
    handleClick() {
      let obj = { iskey: "newspage", mkh: this.mkh, anymore: true };
      this.$EventBus.emit("toParent", obj);
    },
  },
};
</script>
<style scoped>
.zj_topic_h2 {
  height: 60px;
  line-height: 60px;
  font-weight: 700;
  background: url(/static/img/zj_blue_bg.jpg) repeat-x left 31px;
}
a {
  text-decoration: none;
  color: #fff;
  width: 406px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background-color: #2e75b6;
}
.zj_training_information {
  float: left;
  padding-right: 15px;
  font-size: 20px;
  color: #333333;
  font-family: "微软雅黑", "黑体";
  /* background: #fbfdfd; */
  background: white;
}
.zj_more_2 {
  float: right;
  background: url(/static/img/zj_red_bg_1.jpg) #fff no-repeat 5px center;
  padding-left: 15px;
  height: 22px;
  /* width: 46px; */
  float: right;
  margin-top: 20px;
}
.zj_more_2 a {
  color: #fff;
  /* background: url(../img/zj_more_1.jpg) no-repeat center center; */
  display: block;
  width: 46px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
}
</style>