<template>
  <div class="w1200">
    <!-- 第一行：关于我们 门户特定-->
    <div>
      <aboutself />
      <div class="clear"></div>
    </div>
    <div v-for="item in rowdata" :key="item.key" style="margin-top: 20px">
      <div
        v-for="cell in mkdata.filter((ce) => ce.SZH === item.name)"
        :key="cell.ID"
        :style="cell.YS"
      >
        <router-view :name="cell.MKLX" :mkh="cell.MKH"></router-view>
      </div>
      <div class="clear"></div>
    </div>
    <!-- <div style="margin-top: 20px">
      <router-view name="friendlink"></router-view>
      <div class="clear"></div>
    </div> -->
  </div>
</template>
<script>
import Axios from "axios";
import aboutself from "../about/AboutGhxh.vue";

export default {
  data() {
    return {
      url: this.env.srvHost + "/sys",
      rowdata: [
        { name: "row1", key: "row1" },
        { name: "row2", key: "row2" },
        { name: "row3", key: "row3" },
        { name: "row4", key: "row4" },
        { name: "row5", key: "row5" },
        { name: "row6", key: "row6" },
        { name: "row7", key: "row7" },
      ],
      mkdata: [],
    };
  },
  components: {
    aboutself,
  },
  mounted() {
    this.getAllModel();
  },
  methods: {
    getAllModel() {
      let my = this;
      Axios.get(my.env.srvHost + "/web/syfmk", {
        params: { portal: my.env.portal },
      })
        .then(function (resp) {
          my.mkdata = resp.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.w1200 {
  /* background: #fbfdfd; */
  width: 1200px;
  margin: 0 auto;
}
.clear {
  clear: both;
}
</style>