import { createApp } from 'vue';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import App from './App.vue';
import axios from 'axios';
import mitt from 'mitt';
import * as Icons from "@element-plus/icons";
// import "./assets/css/ckhWeb.css";

const app = createApp(App);
app.config.globalProperties.$EventBus = mitt();
app.use(ElementPlus);
app.use(router);

Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key]);
});

sysRun();

async function sysRun() {
    let getConfig = await axios.get("./static/config.json");
    let config = getConfig.data;
    app.config.globalProperties.env = config;

    let pt = config.portal;
    // let cssurl;
    // if (pt == "ckh") {
    //     cssurl = "static/css/ckhWeb.css";
    // } else if (pt == "ghxh") {
    //     cssurl = "static/css/ghxhWeb.css";
    // } else if (pt == "ghy") {
    //     cssurl = "static/css/ghyWeb.css";
    // } else {
    //     cssurl = "static/css/ghyWeb.css";
    // }
    // var headHTML = document.getElementsByTagName('head')[0].innerHTML;
    // headHTML += '<link type="text/css" rel="stylesheet" href="' + cssurl + '">';
    // document.getElementsByTagName('head')[0].innerHTML = headHTML;
    if (pt == "ckh") {
        import("@/assets/css/ckhWeb.css");
    } else if (pt == "ghxh") {
        import("@/assets/css/ghxhWeb.css");
    } else if (pt == "ghy") {
        import("@/assets/css/ghyWeb.css");
    } else {
        import("@/assets/css/ghyWeb.css");
    }

    await axios.get(config.srvHost + "/web/get/config", {
        params: {
            portal: config.portal,
        },
    })
        .then(resp => {
            let data = resp.data;
            config.xtmc = data.xtmc;
            let arr = data.menu;
            let para = {};
            let menuPara = [];
            for (let i = 0; i < arr.length; i++) {
                let ele = arr[i];
                if (ele.MKH) {
                    para[ele.MKH] = ele;
                }
                if (ele.CDSX > 0) {
                    menuPara.push(ele);
                }
            }
            config.menu = menuPara;
            config.mkparas = para;

            app.mount('#app');
        });
}